<template>
  <v-app v-resize="onResize">
    <v-main v-if="!$store.state.sessionKey && (!$store.state.responses || reload)">
      <v-card class="py-4 d-flex align-center justify-center text-subtitle-1" rounded="0"
        style="width:100%; position:absolute" color="red" v-if="error">{{ error }}</v-card>
      <v-container class="d-flex align-center justify-center fill-height">
        <v-card width="300px" elevation="20">
          <v-form>
            <v-text-field hide-details v-model="user" label="Benutzername"></v-text-field>
            <v-text-field hide-details v-model="password" type="password" label="Passwort"
              @keyup.enter="login()"></v-text-field>
            <v-btn block @click="login()" color="green">Login</v-btn>
            <v-btn v-if="$store.state.responses" block @click="reload = false" color="orange">abbrechen</v-btn>
          </v-form>
        </v-card>
      </v-container>
    </v-main>
    <v-main v-else-if="$store.state.responses">
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Logout?
          </v-card-title>

          <v-card-text>
            Willst du dich wirklich wirklich wirklich ausloggen? Also bist du sicher?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="logout()">
              Logout
            </v-btn>

            <v-btn color="green darken-1" text @click="dialog = false">
              abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container fluid class="d-flex align-begin justify-center ma-0 pa-0 fill-height" style="background-color:#FFAE03">
        <v-card elevation="20" :height="!display.mobile.value ? (windowSize.y - 80) : windowSize.y"
          class="mt-sm-10 overflow-y-hidden" :min-width="display.mobile.value ? '100%' : '500px'"
          :max-width="display.mobile.value ? '100%' : '500px'">
          <v-toolbar class="d-flex " color="green">
            <v-text-field v-model="searchText" prepend-inner-icon="mdi-account-search-outline" variant="underlined"
              hide-details density="compact">
            </v-text-field>
            <v-spacer></v-spacer>
            <v-btn icon="mdi-refresh" @click="reload_responses"></v-btn>
            <v-btn @click="dialog=true" icon="mdi-logout"></v-btn>
          </v-toolbar>
          <v-sheet class="overflow-y-auto" :height="!display.mobile.value ? (windowSize.y - 80 - 56) : windowSize.y - 56">
            <router-view></router-view>
            <v-overlay absolute style="top:56px" :close-on-content-click="false" contained v-model="detailOverlay">
              <ChildView @closeDetails="closeDetails"
                :viewHeight="!display.mobile.value ? (windowSize.y - 80 - 56) : windowSize.y - 56"
                :detailResponse="$store.state.detailResponse"></ChildView>
            </v-overlay>
          </v-sheet>
        </v-card>
      </v-container>

    </v-main>
    <v-main v-else>
      <v-container class="d-flex justify-center align-center">
        <p class="text-h1">loading</p>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { useDisplay } from 'vuetify'
import axios from 'axios'
import ChildView from './components/childView.vue'
export default {
  setup() {
    const display = useDisplay();
    return { display };
  },
  name: "App",
  data: () => ({
    user: "",
    password: "",
    sessionkey: "",
    error: null,
    error_timer: null,
    searchText: "",
    reload: false,
    dialog: false,
    windowSize: {
      x: 0,
      y: 0
    },
    detailOverlay: false,
  }),
  methods: {
    closeDetails () {
      this.detailOverlay = false
      this.$store.commit("setDetailResponse", null)
    },
    logout () {
      this.$store.commit("setResponses", null)
      this.$store.commit("deleteSessionKey")
      this.dialog=false
    },
    async reload_responses() {
      await this.$store.dispatch("getResponses")
      if (!this.$store.state.sessionKey) {
        this.reload = true
      }
    },
    search() {
      this.$store.commit("setSearch", this.searchText);
      if (this.$routepath != "/") {
        this.$router.push("/");
      }
    },
    async login() {
      axios.defaults.headers.post["Content-Type"] = "application/json";
      const res = await axios({
        method: "post",
        url: "https://formulare.netzwerk-schule.net/index.php/admin/remotecontrol",
        headers: {
          "content-type": "application/json"
        },
        data: {
          method: "get_session_key",
          params: [this.user, this.password],
          id: 1
        }
      });
      if (!res.data.result.status) {
        this.$store.commit("setSessionKey", res.data.result);
        this.$store.commit("setUser", this.user);
        this.$store.dispatch("getResponses")
      }
      else {
        this.error = res.data.result.status;
      }
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    }
  },
  watch: {
    error(err) {
      if (err) {
        let self = this;
        clearTimeout(this.error_timer);
        this.error_timer = setTimeout(function () {
          self.error = null;
        }, 3000);
      }
    },
    searchText() {
      this.search();
    },
    '$store.state.detailResponse'(response) {
      if (response) {
        this.detailOverlay = true
      } else {
        this.detailOverlay = false
        this.$store.commit('setDetailResponse', null)
      }
    }
  },
  mounted() {
    if (this.$store.state.sessionKey) {
      this.$store.dispatch("getResponses");
    }
    this.onResize();
    this.$store.commit("deleteSearch");
    if (Date.now() - this.$store.state.lastRefresh > 864000000) {
      this.$store.commit("setResponses", null)
      console.log("reset!")
    }
    console.log(this.$store.state.responses)
  },
  created() {
    document.title = "Sommerschulfahrt 2022"
  },
  components: { ChildView }
}
</script>
