<template>
   <svg version="1.1" id="Capa_1" x="0px" y="0px" width="50" height="50" viewBox="0 0 941.214 941.214"
      style="enable-background:new 0 0 941.214 941.214;" xml:space="preserve"
      sodipodi:docname="swimming-figure-svgrepo-com.svg" inkscape:version="1.2 (56b05e47e7, 2022-06-09, custom)"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <g id="g240" transform="translate(4.7018681,157.21478)">

         <path style="fill:currentColor"
            d="m 119.575,293.47 217.8,28 c 7.601,19.3 45.9,114.3 53.101,131.3 0,0 0,0 -0.101,0 -63.1,31.2 -126.2,62.3 -189.3,93.5 21.9,-4 44.1,-6 66.4,-6 29.2,0 57.8,3.5 85.1,10.4 55.1,14 104.9,36.399 153,58.1 42.9,19.3 83.5,37.601 124,48.101 5.7,1.5 11.2,2.8 16.7,3.899 -48,-98.3 -177.1,-363.2 -202.6,-421.5 -12,-27.5 -28.5,-37.7 -51.899,-40.7 l -255.8,-32.9 c -35.3,-4.5 -67.6,20.4 -72.2,55.7 -4.401,35.3 20.499,67.6 55.799,72.1 z"
            id="path232" />
         <path style="fill:currentColor"
            d="m 16.675,752.87 c 7.1,5.1 15.2,7.5 23.3,7.5 1.8,0 3.5,-0.101 5.2,-0.4 10.6,-1.4 20.6,-7 27.3,-16.3 25.9,-36.101 66,-64.5 112.7,-80.101 26.8,-8.899 54.8,-13.399 82.3,-13.399 99.7,0 185.2,59.6 275.199,93.899 48.2,18.4 98.5,32 153.2,32 23,0 46.8,-2.399 71.5,-7.899 58.101,-12.8 107.9,-40.4 143.9,-79.101 8.6,-9.3 16.399,-19.1 23.3,-29.6 12.2,-18.4 7.1,-43.2 -11.3,-55.4 -6.8,-4.5 -14.4,-6.6 -22,-6.6 -13,0 -25.7,6.3 -33.4,17.9 -3.1,4.699 -6.5,9.199 -10.2,13.6 -25,29.8 -62.5,51.2 -107.5,61.1 -41.5,9.101 -87.3,6.4 -128.199,-4.199 -43,-11.2 -84.601,-29.9 -128.801,-49.801 -47,-21.1 -95.5,-43 -148.099,-56.399 -25,-6.4 -51.2,-9.5 -77.7,-9.5 -36.1,0 -72.8,5.899 -107.7,17.5 -30.5,10.2 -58.8,24.399 -84.2,42.3 -26.8,18.9 -49.7,41.4 -68.1,67 -1.2,1.7 -2.2,3.4 -3.1,5.2 -8.7,17.3 -3.9,39 12.4,50.7 z"
            id="path234" />
         <circle style="fill:currentColor" cx="740.87402" cy="426.16901" r="141.10001" id="circle236" />

      </g>

      <g id="g1381" transform="matrix(0.68392218,0,0,0.68392218,149.98473,76.157008)">
         <path sodipodi:type="star"
            style="fill:#3090eb;fill-opacity:1;stroke:#000000;stroke-width:7.2;stroke-dasharray:none;stroke-opacity:1"
            id="path1227-9" inkscape:flatsided="false" sodipodi:sides="5" sodipodi:cx="35.332817"
            sodipodi:cy="40.986404" sodipodi:r1="169.67439" sodipodi:r2="84.837204" sodipodi:arg1="0.71595204"
            sodipodi:arg2="1.3442706" inkscape:rounded="0" inkscape:randomized="0"
            d="M 163.34684,152.34982 54.386691,123.65624 -31.021573,197.14816 -37.402894,84.654098 -133.69047,26.136266 -28.674198,-14.695308 -2.7749317,-124.35324 68.510017,-37.09448 180.80422,-46.348979 119.84447,48.411477 Z"
            inkscape:transform-center-x="11.775943" inkscape:transform-center-y="-4.5889456"
            transform="translate(-72.001601,54.306638)" />
         <path sodipodi:type="star"
            style="fill:#3090eb;fill-opacity:1;stroke:#000000;stroke-width:7.2;stroke-dasharray:none;stroke-opacity:1"
            id="path1227-9-3" inkscape:flatsided="false" sodipodi:sides="5" sodipodi:cx="35.332817"
            sodipodi:cy="40.986404" sodipodi:r1="169.67439" sodipodi:r2="84.837204" sodipodi:arg1="0.71595204"
            sodipodi:arg2="1.3442706" inkscape:rounded="0" inkscape:randomized="0"
            d="M 163.34684,152.34982 54.386691,123.65624 -31.021573,197.14816 -37.402894,84.654098 -133.69047,26.136266 -28.674198,-14.695308 -2.7749317,-124.35324 68.510017,-37.09448 180.80422,-46.348979 119.84447,48.411477 Z"
            inkscape:transform-center-x="11.775943" inkscape:transform-center-y="-4.5889456"
            transform="translate(273.78953,54.306638)" />
         <path sodipodi:type="star"
            style="fill:#3090eb;fill-opacity:1;stroke:#000000;stroke-width:7.2;stroke-dasharray:none;stroke-opacity:1"
            id="path1227-9-6" inkscape:flatsided="false" sodipodi:sides="5" sodipodi:cx="35.332817"
            sodipodi:cy="40.986404" sodipodi:r1="169.67439" sodipodi:r2="84.837204" sodipodi:arg1="0.71595204"
            sodipodi:arg2="1.3442706" inkscape:rounded="0" inkscape:randomized="0"
            d="M 163.34684,152.34982 54.386691,123.65624 -31.021573,197.14816 -37.402894,84.654098 -133.69047,26.136266 -28.674198,-14.695308 -2.7749317,-124.35324 68.510017,-37.09448 180.80422,-46.348979 119.84447,48.411477 Z"
            inkscape:transform-center-x="11.775943" inkscape:transform-center-y="-4.5889456"
            transform="translate(619.58065,54.306638)" />
         <path sodipodi:type="star"
            style="fill:#3090eb;fill-opacity:1;stroke:#000000;stroke-width:7.2;stroke-dasharray:none;stroke-opacity:1"
            id="path1227-9-0" inkscape:flatsided="false" sodipodi:sides="5" sodipodi:cx="35.332817"
            sodipodi:cy="40.986404" sodipodi:r1="169.67439" sodipodi:r2="84.837204" sodipodi:arg1="0.71595204"
            sodipodi:arg2="1.3442706" inkscape:rounded="0" inkscape:randomized="0"
            d="M 163.34684,152.34982 54.386691,123.65624 -31.021573,197.14816 -37.402894,84.654098 -133.69047,26.136266 -28.674198,-14.695308 -2.7749317,-124.35324 68.510017,-37.09448 180.80422,-46.348979 119.84447,48.411477 Z"
            inkscape:transform-center-x="11.775943" inkscape:transform-center-y="-4.5889456"
            transform="translate(965.37178,54.306638)" />
      </g>
   </svg>
</template>

<script>
export default {
   name: "SwimIcon",
}
</script>