<template>
    <v-card class="pa-1 d-flex flex-column align-begin" v-if="response" @click="$store.commit('setDetailResponse', response)">
        <v-card-title class="py-1 pl-1">{{ response.KindRufname }} <v-icon class="ml-2" icon="mdi-information-variant" color="orange" v-if="response.AbschlussHinweise != ''" ></v-icon></v-card-title> 
        <v-lazy :options="{
            threshold: 2
        }" min-height="41" transition="fade-transition">
            <div class="d-flex">
                <v-card :class="groupClass" border="20">
                    <div class="text-caption card-caption">Unterkunft</div>

                    <v-icon :class="iconClass" :size="size" :color="response.UnterkunftGeschlecht ? 'green' : 'red'"
                        icon="mdi-gender-transgender"></v-icon>
                    <v-icon :class="iconClass" :size="size" :color="response.UnterkunftGelVerl ? 'green' : 'red'"
                        :icon="$vuetify.icons.leavehouse"></v-icon>
                </v-card>
                <v-card :class="groupClass" border="20">
                    <div class="text-caption card-caption">Freizeit</div>
                    <v-icon :class="iconClass" :size="size" :color="response.FreizeitDarfSchwimm ? 'green' : 'red'"
                        :icon="swimSkillIcon">
                    </v-icon>
                    <v-icon :class="iconClass" :size="size" :color="response.FreizeitFahrrad ? 'green' : 'red'"
                        icon="mdi-bike-fast"></v-icon>
                    <v-icon :class="iconClass" :size="size"
                        :color="['oErw', 'mErw'].includes(response.FreizeitBoot) ? 'green' : 'red'" :icon="boatIcon">
                    </v-icon>
                </v-card>
                <v-card :class="groupClass" border="20">
                    <div class="text-caption card-caption">Medizinisch</div>
                    <v-icon :class="iconClass" :size="size" :color="response.MedSonnencreme ? 'green' : 'red'"
                        :icon="$vuetify.icons.sonnencreme">
                    </v-icon>
                    <v-icon :class="iconClass" :size="size" :color="response.MedTetanus ? 'green' : 'red'"
                        icon="mdi-needle">
                    </v-icon>
                    <v-icon :class="iconClass" :size="size" :color="response.MedVersorgung ? 'green' : 'red'"
                        icon="mdi-hospital-box">
                    </v-icon>
                    <v-icon v-if="response.MedBrauchtMedikament" :class="iconClass" :size="size"
                        :color="response.MedBrauchtMedikament ? 'orange' : 'green'" icon="mdi-pill">
                    </v-icon>
                    <v-icon v-if="response.MedInformation != ''" :class="iconClass" :size="size" color="orange"
                        icon="mdi-information-variant">
                    </v-icon>
                </v-card>
            </div>
        </v-lazy>
    </v-card>
    <v-divider></v-divider>

</template>
<script>

export default {
    props: {
        response: Object,
        name: Boolean,
        size: String
    },
    emits: ['showDetails'],
    data() {
        return {
            iconClass: "mr-1 my-1",
            groupClass: "mt-0 pt-2 px-1 mx-1"
        }
    },
    computed: {
        swimSkillIcon() {
            switch (this.response.FreizeitSchwimmSkill) {
                case "0":
                    return this.$vuetify.icons.swimeins
                case "1":
                    return this.$vuetify.icons.swimzwei
                case "2":
                    return this.$vuetify.icons.swimdrei
                case "3":
                    return this.$vuetify.icons.swimvier
                default:
                    return "null"
            }
        },
        boatIcon() {
            switch (this.response.FreizeitBoot) {
                case "oErw":
                    return this.$vuetify.icons.boatwithoutadult
                case "mErw":
                    return this.$vuetify.icons.boatwithadult
                default:
                    return this.$vuetify.icons.noboat

            }
        }
    }
}
</script>

<style scoped>
.card-caption {
    position: absolute;
    top: -5px;
    left: 10px
}
</style>