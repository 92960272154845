<template>
  <v-card v-if="response">
    <v-sheet style="background: rgba(256, 256, 256,  0.7) ; position:absolute;z-index:10" width="100%"
      class="text-h6 pa-2 d-flex">
      {{ response.KindRufname }}
      <v-spacer></v-spacer>
      <v-icon @click="$emit('closeDetails')" variant="outlined" size="large" color="red" icon="mdi-close" class="mr-2">
      </v-icon>
    </v-sheet>
    <div :style="'height:' + viewHeight.toString() + 'px;overflow:auto'">
      <div class="d-flex flex-column">

        <v-list density="compact" class="mt-8">
          <v-list-subheader>Medizinische Versorgung</v-list-subheader>
          <v-list-item>
            <v-list-item-icon icon="mdi-magnify" :size="size" :color="icon(response.MedLaeuse).color" class="mr-2">
            </v-list-item-icon>
            darf <span v-if="!response.MedLaeuse">&nbsp; nicht &nbsp;</span> auf Läuse überprüft werden
          </v-list-item>
          <v-list-item>
            <v-list-item-icon :class="iconClass" :size="size"
              :color="response.MedBrauchtMedikament ? 'orange' : 'green'" icon="mdi-pill"></v-list-item-icon>
            <span>braucht <span v-if="!response.MedBrauchtMedikament">keine</span> Medikamente</span>
          </v-list-item>
          <v-list-item v-for="(med, index) in response.MedMedikamenteListe" :key="index">
            <v-list-item-icon icon="mdi-pill" class="ml-8 mr-2"></v-list-item-icon>
            {{ med }}
          </v-list-item>
          <v-list-item>
            <v-list-item-icon :size="size" :icon="$vuetify.icons.sonnencreme"
              :color="icon(response.MedSonnencreme).color" class="mr-2"></v-list-item-icon>
            <span>darf <span v-if="!response.MedSonnencreme">nicht</span> mit Sonnencreme eingecremt
              werden</span>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon :size="size" icon="mdi-needle" :color="icon(response.MedTetanus).color" class="mr-2">
            </v-list-item-icon>
            <span>hat <span v-if="!response.MedTetanus">k</span>eine gültige Tetanus Impfung</span>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon :class="iconClass" :size="size" :color="response.MedVersorgung ? 'green' : 'red'"
              icon="mdi-hospital-box"></v-list-item-icon>
            <span v-if="!response.MedVersorgung">darf nur in Notfällen medizinisch versorgt werden</span>
            <span v-if="response.MedVersorgung">darf auch bei Nicht-Notfällen medizinisch versorgt
              werden</span>
          </v-list-item>
          <v-list-item class="font-italic" v-if="response.MedInformation != ''">
            <v-list-item-icon icon="mdi-comment-quote" class="mr-2" color="grey"></v-list-item-icon>
            <span>{{ response.MedInformation }}</span>
          </v-list-item>
        </v-list>

        <v-list density="compact">
          <v-list-subheader>Unterkunft</v-list-subheader>
          <v-list-item>
            <v-list-item-icon :class="iconClass" :size="size" :color="response.UnterkunftGeschlecht ? 'green' : 'red'"
              icon="mdi-gender-transgender">
            </v-list-item-icon>
            <span v-if="response.UnterkunftGeschlecht">darf mit andersgeschlechtlichen Kindern in einem Zimmer schlafen</span>
            <span v-else>darf nicht mit andersgeschlechtlichen Kindern in einem Zimmer schlafen</span>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon :class="iconClass" :size="size" :color="response.UnterkunftGelVerl ? 'green' : 'red'"
              :icon="$vuetify.icons.leavehouse">
            </v-list-item-icon>
            <span v-if="response.UnterkunftGelVerl">darf das Gelände mit zwei anderen verlassen</span>
            <span v-if="!response.UnterkunftGelVerl">darf das Gelände nur mit Erwachsenen verlassen</span>
          </v-list-item>
        </v-list>


        <v-list density="compact">
          <v-list-subheader>Freizeit</v-list-subheader>
          <v-list-item>
            <v-list-item-icon :class="iconClass" :size="size"
              :color="['oErw', 'mErw'].includes(response.FreizeitBoot) ? 'green' : 'red'" :icon="boatIcon">
            </v-list-item-icon>
            <span v-if="response.FreizeitBoot == 'oErw'">darf mit zwei anderen Kindern Boot fahren</span>
            <span v-if="response.FreizeitBoot == 'mErw'">darf mit einem Erwachsenen Bootfahren</span>
            <span v-if="!response.FreizeitBoot">darf nicht Boot fahren</span>
          </v-list-item>
          <v-list-item>
            <span class="mr-2">Schwimmlevel:</span>
            <v-list-item-icon :size="size" v-for="index in 4" :key="index" icon="mdi-swim"
              :color="(index - 1) > parseInt(response.FreizeitSchwimmSkill) ? 'grey' : 'blue'"></v-list-item-icon>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon :class="iconClass" :size="size" :color="response.FreizeitDarfSchwimm ? 'green' : 'red'"
              :icon="swimSkillIcon">
            </v-list-item-icon>
            <span>darf <span v-if="!response.FreizeitDarfSchwimm">nicht</span> schwimmen/baden</span>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon :size="size" icon="mdi-bike-fast" :color="icon(response.FreizeitFahrrad).color"
              class="mr-2">
            </v-list-item-icon>
            <span>kann <span v-if="!response.FreizeitFahrrad">nicht</span> sicher Fahrrad fahren</span>
          </v-list-item>
        </v-list>

        <v-list density="compact" v-if="response.AbschlussHinweise != ''" :class="categoryCard">
          <v-list-subheader>Sonstiges</v-list-subheader>
          <v-list-item class="font-italic">
            <v-list-item-icon icon="mdi-comment-quote" class="mr-2" color="grey"></v-list-item-icon>
            <span>{{ response.AbschlussHinweise }}</span>
          </v-list-item>
        </v-list>

        <v-list>
          <v-list-subheader>Kontakt</v-list-subheader>
          <v-list-item density="compact" class="font-italic" v-for="(item, index) in response.contacts" :key="index">
            <v-btn size="default" class="mr-2" variant="text" :href="'tel:' + item.nummer" icon="mdi-phone" color="green"></v-btn><span>{{ item.name }}</span>
            <v-spacer></v-spacer>{{ item.nummer }}
          </v-list-item>
        </v-list>

      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ChildView',
  props: ["detailResponse", "viewHeight"],
  emits: ['closeDetails'],
  data: () => ({
    iconClass: 'mr-2',
    response: null,
    categoryCard: "ma-2",
    size: "default"
  }),
  methods: {
    icon(value) {
      if (value) {
        return { name: "mdi-check", color: "green" }
      } else {
        return { name: "mdi-minus-circle", color: "red" }
      }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.response = this.$store.state.responses.filter(response => { return response.id === this.$route.params.id })[0]
    } else if (this.detailResponse) {
      this.response = this.detailResponse
    }
  },
  computed: {
    swimSkillIcon() {
      switch (this.response.FreizeitSchwimmSkill) {
        case "0":
          return this.$vuetify.icons.swimeins
        case "1":
          return this.$vuetify.icons.swimzwei
        case "2":
          return this.$vuetify.icons.swimdrei
        case "3":
          return this.$vuetify.icons.swimvier
        default:
          return "null"
      }
    },
    boatIcon() {
      switch (this.response.FreizeitBoot) {
        case "oErw":
          return this.$vuetify.icons.boatwithoutadult
        case "mErw":
          return this.$vuetify.icons.boatwithadult
        default:
          return this.$vuetify.icons.noboat

      }
    }
  }
}
</script>

<style scoped>
.card-caption {
  position: absolute;
  top: -5px;
  left: 10px
}
</style>