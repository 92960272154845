<template>
    <v-card v-if="filtered_responses.length > 0" class="d-flex flex-column pa-0" >
        <short-view :size="displaySize(display)" :name="true" v-for="response in filtered_responses" :key="response" :response="response" />
    </v-card>
</template>
<script>
import shortView from './shortView.vue'
import { ref} from "vue";
import { useDisplay } from 'vuetify'
export default{
    setup () {
        const display = ref(useDisplay())
        return {display}
    },
    props: {
        windowSize: Object
    },
    components: { shortView },
    data() {
        return {
            responses: null,
        }
    },
    mounted() {
    },
    computed: {
        filtered_responses() {
            return this.$store.state.responses.filter(response =>
                response.KindRufname.toLowerCase().includes(this.$store.state.searchText.toLowerCase())).sort((a, b) => (a.KindRufname > b.KindRufname) ? 1 : ((b.KindRufname > a.KindRufname) ? -1 : 0))
        },
    },
    methods: {
        displaySize (display) {
            if (display.width >= 423) {
                return 'x-large'
            } else if (display.width >= 387){
                return 'large'
            } else if (display.width >= 343){
                return 'default'
            } else if (display.width >= 303){
                return 'small'
            } else {
                return 'x-small'
            }
        }
    },
    watch: {
    }
}
</script>
