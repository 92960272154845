import { createStore } from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
    sessionKey: null,
    user: null,
    responses: null,
    searchText: "",
    detailResponse: null,
    lastRefresh: null
  },
  getters: {
  },
  mutations: {
    setSessionKey(state, key) {
      state.sessionKey = key
      this.dispatch('getResponses')
      state.lastRefresh = Date.now()
    },
    deleteSessionKey(state) {
      state.sessionKey = null
    },
    setUser(state, user) {
      state.user = user
    },
    setResponses(state, responses) {
      console.log(">>")
      console.log(responses)
      console.log("<<")
      state.responses = responses
    },
    setSearch (state, text) {
      state.searchText = text
    },
    deleteSearch (state) {
      state.searchText = ""
    },
    setDetailResponse(state, response) {
      if (response) {
        state.detailResponse = response
      } else {
        state.detailResponse = null
      }
    }

  },
  actions: {
    async getResponses(context) { 
      const res = await axios({
        method: 'post',
        url: "https://formulare.netzwerk-schule.net/index.php/admin/remotecontrol",
        headers: {
          'content-type': 'application/json',
        },
        data: {
          method: 'export_responses',
          params: [context.state.sessionKey, 522647, 'json', '', 'complete'],
          id: 1
        }
      });
      if (res.data.result?.status=="Invalid session key") {
        context.commit('deleteSessionKey')
        return "error"
      }
      let responses = JSON.parse(atob(res.data.result)).responses
      for (let i = 0; i < responses.length; i++) {
        responses[i].MedMedikamenteListe = []
        responses[i].contacts = []
        for (let m = 1; m < 4; m++) {
          let contact = {
            name: responses[i]["AbschlussTelefon[tel" + m + "_Name]"],
            nummer: responses[i]["AbschlussTelefon[tel" + m + "_Nummer]"]
          }
          contact.nummer != "" && contact.nummer != null ? responses[i].contacts.push(contact) : null
        }
        for (let m = 1; m < 5; m++) {
          let med = responses[i]["MedMedikamenteListe[med" + m + "]"]
          med != "" && med != null ? responses[i].MedMedikamenteListe.push(med) : null
        }
        for (var prop in responses[i]) {
          if (responses[i][prop] == "true") {
            responses[i][prop] = true
          } else if (responses[i][prop] == "false") {
            responses[i][prop] = false
          }
        }
      }
      context.commit('setResponses', responses)
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
