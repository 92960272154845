// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { mdi } from 'vuetify/iconsets/mdi'
import { aliases, fa } from 'vuetify/iconsets/fa'
import LeaveHouse from '../icons/LeaveHouse.vue'
import Sonnencreme from '../icons/Sonnencreme.vue'
import SwimEins from '../icons/SwimEins.vue'
import SwimZwei from '../icons/SwimZwei.vue'
import SwimDrei from '../icons/SwimDrei.vue'
import SwimVier from '../icons/SwimVier.vue'
import BoatWithAdult from '../icons/boatWithAdult.vue'
import NoBoat from '../icons/noBoat.vue'
import BoatWithoutAdult from '../icons/boatWithoutAdult.vue'
import { shallowRef} from 'vue'

const leavehouse = shallowRef(LeaveHouse)
const sonnencreme = shallowRef(Sonnencreme)
const swimeins = shallowRef(SwimEins)
const swimzwei = shallowRef(SwimZwei)
const swimdrei = shallowRef(SwimDrei)
const swimvier = shallowRef(SwimVier)
const boatwithadult=shallowRef(BoatWithAdult)
const noboat=shallowRef(NoBoat)
const boatwithoutadult=shallowRef(BoatWithoutAdult)
// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  icons: {
    leavehouse: leavehouse,
    sonnencreme: sonnencreme,
    swimeins: swimeins,
    swimzwei: swimzwei,
    swimdrei: swimdrei,
    swimvier: swimvier,
    boatwithoutadult: boatwithoutadult,
    boatwithadult: boatwithadult,
    noboat: noboat,
    defaultSet: 'mdi',
    aliases,
    sets: {
      fa,
      mdi,
    }
  },
  display: {
    mobileBreakpoint: 'sm'
  }
})